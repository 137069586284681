import './styles.scss';
import React from 'react';
import {CourseModel} from "../../services/courseService";
import {CalendarIcon, OpenBookIcon, TimeIcon} from "../../images";
import {WITH_API_BASE} from "../../configs/appConfigs";
import {Link} from "gatsby";

type Props = {
    data: CourseModel;
}

const CourseListItem = ({data}: Props) => {
    return (
        <div className="courseListItemWrap">
            <div className="courseListItem">
                <div className="courseListItemLeft">
                    <img src={WITH_API_BASE(data?.attributes?.Image?.data?.attributes?.url || '')} alt=""/>
                </div>
                <div className="courseListItemData">
                    <div className="courseListItemTitle">{data?.attributes?.Course_Name}</div>
                    <div className="courseListItemAttrs">
                        <div className="courseListItemAttr">
                            <span className="courseListItemIcon"><img src={CalendarIcon} width={20} alt=""/></span>
                            <span>{data?.attributes?.Duration}</span>
                        </div>
                        <div className="courseListItemAttr">
                        <span className="courseListItemIcon">
                        <img src={OpenBookIcon} width={24} alt=""/>
                        </span>
                            <span>{data?.attributes?.Lessons}</span>
                        </div>
                        <div className="courseListItemAttr">
                        <span className="courseListItemIcon">
                        <img src={TimeIcon} width={16} alt=""/>
                        </span>
                            <span>{data?.attributes?.LessonDuration}</span>
                        </div>
                    </div>

                    <div className="courseListItemDesc">{data?.attributes?.Description}</div>

                    <Link to={`/course/${data?.id}`} className="courseListItemBtn">Details</Link>
                </div>
            </div>
            <div className="courseListItemBottom">
                <div className="courseListItemMobDesc">{data?.attributes?.Description}</div>
                <Link to={`/course/${data?.id}`} className="courseListItemBtn">Details</Link>
            </div>
        </div>
    )
}

export default CourseListItem;

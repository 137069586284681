import './styles.scss';
import React, {useEffect, useMemo, useState} from 'react';
import CourseService, {CourseCategory, CourseLevel} from "../../services/courseService";
import {TickImage} from "../../images";

export type FilterChangeEvent = {
    categories: number[];
    levels: number[];
    query: string;
}

type Props = {
    onFilterChange: (e: FilterChangeEvent) => void;
}

const ListFilters = ({onFilterChange}: Props) => {
    const [query, setQuery] = useState('');
    const [levels, setLevels] = useState<Array<CourseLevel>>([]);
    const [categories, setCategories] = useState<Array<CourseCategory>>([]);
    const [selectedLevels, setSelectedLevels] = useState<number[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

    useEffect(() => {
        CourseService.getCourseLevels().then((r) => {
            setLevels(r.data.data)
        }).catch(() => {
        })
        CourseService.getCourseCategories().then((r) => {
            setCategories(r.data.data)
        }).catch(() => {
        })
    }, []);

    const selectLevel = (id: number) => {
        const newLevels = [id];
        setSelectedLevels(newLevels);
    }

    useEffect(() => {
        if (onFilterChange) {
            onFilterChange({
                categories: selectedCategories,
                levels: selectedLevels,
                query
            })
        }
    }, [selectedLevels, selectedCategories, query])

    const selectCategories = (id: number) => {
        const selected = selectedCategories.includes(id);
        if (selected) {
            setSelectedCategories(selectedCategories.filter(l => l !== id))
        } else {
            setSelectedCategories([...selectedCategories, id])
        }
    }

    const renderLevels = useMemo(() => {
        return levels.map((level) => {
            return (
                <li key={`item-${level.id}`} className="listFilterListItem">
                    <div onClick={() => selectLevel(level.id)}
                         className={`radiobox ${selectedLevels.includes(level.id) ? 'selected' : ''}`}></div>
                    <div className="listFilterListItemLabel">{level.attributes.Name}</div>
                </li>
            )
        })
    }, [levels, selectedLevels])

    const renderCategories = useMemo(() => {
        return categories.map((cat) => {
            return (
                <li key={`item-${cat.id}`}
                    className={`listFilterListItem ${selectedCategories.includes(cat.id) ? 'selected' : ''}`}>
                    <div onClick={() => selectCategories(cat.id)}
                         className={`checkbox ${selectedCategories.includes(cat.id) ? 'selected' : ''}`}>
                        <img src={TickImage} width={12} alt=""/>
                    </div>
                    <div className="listFilterListItemLabel">{cat.attributes.Name}</div>
                </li>
            )
        })
    }, [categories, selectedCategories])

    const filtersCount = useMemo(() => {
        return (selectedLevels.length + selectedCategories.length)
    }, [selectedLevels, selectedCategories]);

    const onReset = () => {
        setSelectedLevels([]);
        setSelectedCategories([]);
        setQuery('');
    }

    return (
        <div className="listFilter">
            <div className="listFilterHead">
                <span>Filters ({filtersCount})</span>
                <button onClick={onReset} className="reset">Reset</button>
            </div>
            <div className="listFilterSearch">
                <input value={query} onChange={event => setQuery(event.target.value)} type="text" placeholder="Search course"/>
            </div>
            <div className="listFilterGroup">
                <div className="listFilterLabel">Level</div>
                <ul className="listFilterList">
                    {renderLevels}
                </ul>
            </div>
            <div className="listFilterGroup">
                <div className="listFilterLabel">Categories</div>
                <ul className="listFilterList">
                    {renderCategories}
                </ul>
            </div>
        </div>
    )
}

export default ListFilters;

import '../styles/pages/list.scss';
import React, {useEffect, useMemo, useState} from 'react';
import {BigLogoImage, CrossIcon, FiltersIcon, LoadingGif} from "../images";
import ListFilters, {FilterChangeEvent} from "../components/ListFilters";
import CourseListItem from "../components/CourseListItem";
import CourseService, {CourseModel} from "../services/courseService";
import {HeadFC, Link} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import MobileNavigation from '../components/MobileNavigation';

const DEFAULT_FILTERS: FilterChangeEvent = {
    query: '',
    categories: [],
    levels: []
}

const ListPage = () => {
    const [list, setList] = useState<CourseModel[]>([]);
    const [isLoading, setLoading] = useState(true);
    const [originList, setOriginList] = useState<CourseModel[]>([]);
    const [filters, setFilters] = useState<FilterChangeEvent>(DEFAULT_FILTERS);
    const [showFilters, setShowFilters] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    const handleFilterChange = (e: FilterChangeEvent) => {
        setFilters(e);
    }

    const updateQuery = (query: string) => {
        setFilters({
            ...filters,
            query
        })
    }

    useEffect(() => {
        setList(originList.filter(item => {
            if (filters.query) {
                return item.attributes.Course_Name?.trim().toLowerCase()?.includes(filters.query.trim().toLowerCase())
            }

            if (filters.levels.length) {
                const levelsIds = item?.attributes?.course_levels?.data.map(l => l.id);
                return (levelsIds || []).filter(li => filters.levels.includes(li)).length > 0;
            }

            if (filters.categories.length) {
                const catIds = item?.attributes?.course_categories?.data.map(l => l.id);
                return (catIds || []).filter(ci => filters.categories.includes(ci)).length > 0;
            }

            return true;
        }))
    }, [filters])

    useEffect(() => {
        CourseService.getList().then(res => {
            setList(res.data.data);
            setOriginList(res.data.data);
        }).catch().finally(() => {
            setLoading(false);
        })
    }, [])

    const renderItems = useMemo(() => {
        return list.map((item, index) => (
            <CourseListItem key={`item-${item.id}`} data={item}/>
        ))
    }, [list]);

    return (
        <div className="container">
            {showMenu && (
                <MobileNavigation onClick={toggleMenu} relation="List" />
            )}
            <div className="listHeader">
                <div className="listHeaderMenu">
                    <ul>
                        <li><Link to="/#tools">Tools</Link></li>
                        <li><Link to="/list">Skills development</Link></li>
                        <li><Link to="/#preorder">Pre-order</Link></li>
                    </ul>
                </div>
                <div className="listHeaderLogo">
                    <Link to="/">
                        <img width={188} src={BigLogoImage} alt=""/>
                    </Link>
                </div>
                <div className="listHeaderMenu">
                    <ul className="align-right">
                        <li><AnchorLink stripHash to="/#contacts">Contacts</AnchorLink></li>
                        <li><a href="https://aeer-partners.com/">Affiliate</a></li>
                    </ul>
                </div>

                <button onClick={toggleMenu} className="mobileBurger">
                    <span></span>
                    <span></span>
                </button>
            </div>

            <div className="mobileFilters">
                <input type="text" placeholder="Search course.." onChange={e => updateQuery(e.target.value)}/>

                <button onClick={() => setShowFilters(!showFilters)} className="mobileFilterBtn">
                    <img src={FiltersIcon} width={21} height={18} alt=""/>
                </button>
            </div>

            <div className="listContent">
                <div className={`listContentLeft ${showFilters ? 'showIn' : ''}`}>
                    <div className="mobileFiltersHead">
                        <button onClick={() => setShowFilters(!showFilters)} className="crossBtn">
                            <img width={24} height={24} src={CrossIcon} alt=""/>
                        </button>
                    </div>
                    <ListFilters onFilterChange={handleFilterChange}/>
                </div>
                <div className="listContentRight">
                    {
                        isLoading && (
                            <div className="loading">
                                <img width={32} src={LoadingGif} alt=""/>
                                <div>Loading..</div>
                            </div>
                        )
                    }
                    {
                        !isLoading && (
                            <div className="listContentItems">
                                {list.length > 0 && (
                                    renderItems
                                )}

                                {!list.length && (
                                    <div className="notFound">No courses found</div>
                                )}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ListPage;

export const Head: HeadFC = () => (
    <>
        <title>Courses Catalog - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
